import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const UUTestReport = makeShortcode("UUTestReport");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av skjemaelementer`}</h2>
    <p>{`Skjemaelementer er en fellesbetegnelse på de komponentene vi har som kan brukes til å samle inn input fra brukere. `}</p>
    <h2>{`Input`}</h2>
    <p><em parentName="p">{`The bread and butter`}</em>{` av skjemaene våre.`}</p>
    <p>{`Vi har tre varianter av inputfelt:`}</p>
    <ol>
      <li parentName="ol">{`standard`}</li>
      <li parentName="ol">{`inline`}</li>
      <li parentName="ol">{`text-like`}</li>
    </ol>
    <p>{`Skillet på `}<em parentName="p">{`Standard`}</em>{` og `}<em parentName="p">{`inline`}</em>{` er mest relevant for utviklere. De ser helt like ut, men oppfører seg annerledes i samspillet
med andre elementer på siden, for eksempel `}<a parentName="p" {...{
        "href": "#tooltip"
      }}>{`tooltip`}</a>{`.`}</p>
    <DSExample name="form_Input" mdxType="DSExample" />
    <UUTestReport reportID="Form_Input" mdxType="UUTestReport" />
    <h3>{`InputTextLike`}</h3>
    <p>{`Text-like input er inputfelter designet for å kunne brukes inni brødtekst, som del av en setning med et minimum av luft rundt. Det er opp til utviklere å sette bredden på dette elementet, siden det vil variere fra gang til gang hva man ønsker.`}</p>
    <p>{`Text-like input har ikke egen label og må derfor assosieres med en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-label`}</code>{`. Dette kan legges til med den obligatoriske `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ariaLabel`}</code>{`-propen i `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`InputTextLike`}</code>{`-komponenten.`}</p>
    <DSExample name="form_InputTextLike" mdxType="DSExample" />
    <h3>{`Styr fokus med `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`ref`}</code></h3>
    <p>{`Du kan få en referanse til input-feltet ved å sende inn en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ref`}</code>{`-prop. Brukes typisk til å fokusere et felt programmatisk.`}</p>
    <DSExample name="form_Input_ref" mdxType="DSExample" />
    <h2>{`FieldMessages`}</h2>
    <UUTestReport reportID="Form_FieldMessage" mdxType="UUTestReport" />
    <h3>{`ErrorFieldMessage`}</h3>
    <p>{`Brukes til å vise feilmeldinger ved et skjemaelement, typisk en valideringsfeil.`}</p>
    <DSExample name="form_ErrorFieldMessage" mdxType="DSExample" />
    <h3>{`InfoFieldMessage`}</h3>
    <p>{`Brukes til å vise informasjonsmeldinger ved et skjemaelement.`}</p>
    <DSExample name="form_InfoFieldMessage" mdxType="DSExample" />
    <h3>{`SuccessFieldMessage`}</h3>
    <p>{`Brukes til å vise suksessmeldinger ved et skjemaelement.`}</p>
    <DSExample name="form_SuccessFieldMessage" mdxType="DSExample" />
    <h2>{`InputGroup`}</h2>
    <p>{`En `}<em parentName="p">{`input group`}</em>{` er en standarisert omverden for enkelt-inputs i et skjema. En gruppe består av en label, et valgfritt
tooltip, skjemaelementet, og en feilmelding for valideringsfeil som vil bli vist om det er feil i skjemaet.`}</p>
    <DSExample name="form_InputGroup" mdxType="DSExample" />
    <UUTestReport reportID="Form_InputGroup" mdxType="UUTestReport" />
    <p>{`Default oppførsel er at det holdes av plass under inputfeltene for å vise en feilmelding uten at innholdet lengre ned endres. Dersom man ikke ønsker dette kan det skrus
av med å sette `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`extraMargin`}</code>{` prop til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`false`}</code>{`.`}</p>
    <DSExample name="form_InputGroup_extraMarginFalse" mdxType="DSExample" />
    <p>{`Dersom man skal vise en hjelpetekst som alltid er synlig brukes `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`description`}</code>{` i stedet for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tooltip`}</code>{`.`}</p>
    <DSExample name="form_InputGroup_description" mdxType="DSExample" />
    <p>{`Utviklere bør merke seg at man er nødt til å bruke det såkalte `}<em parentName="p">{`function-as-a-child`}</em>{`-patternet med mindre man bare har
ett child-element til InputGroup. Dette er fordi InputGroup setter properties som ID og liknende på rot-elementet, og
det forventes at det er et skjemaelement.`}</p>
    <p>{`Et eksempel med flere children kan du se her:`}</p>
    <DSExample name="form_InputGroup_multiChildren" mdxType="DSExample" />
    <p>{`Sender man inn en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`string`}</code>{` eller et `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<ErrorFieldMessage>`}</code>{`-element til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fieldMessage`}</code>{` vil dette rendres som en feilmelding:`}</p>
    <DSExample name="form_InputGroup_fieldMessage" mdxType="DSExample" />
    <h2>{`Label`}</h2>
    <p>{`Om du ikke bruker InputGroup kan du fremdeles bruke deler av den, som f.eks. Label.`}</p>
    <DSExample name="form_Label" mdxType="DSExample" />
    <UUTestReport reportID="Form_Label" mdxType="UUTestReport" />
    <h2>{`Checkbox`}</h2>
    <p>{`Checkbox brukes der du har 4 eller færre valgalternativer, og du vil gi brukeren muligheten til å velge mer enn ett alternativ. `}</p>
    <DSExample name="form_Checkbox" mdxType="DSExample" />
    <p>{`Ved å sende inn `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`inline={false}`}</code>{`, kan de også vises under hverandre:`}</p>
    <DSExample name="form_Checkbox_inlineFalse" mdxType="DSExample" />
    <p>{`Hvis du skal ha en skjult label, brukes `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hiddenLabel={true}`}</code>{`, og label sendes fortsatt inn som child:`}</p>
    <DSExample name="form_Checkbox_hiddenLabel" mdxType="DSExample" />
    <p>{`Du kan merke at et felt er ugyldig ved å sette `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-invalid="true"`}</code>{`:`}</p>
    <DSExample name="form_Checkbox_invalid" mdxType="DSExample" />
    <p>{`Du kan kan sende inn en callback-funksjon som blir kalt hver gang verdien i checkboxen endrer
seg med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onChange`}</code>{`:`}</p>
    <DSExample name="form_Checkbox_onChange" mdxType="DSExample" />
    <p>{`Du kan sende inn children som en funksjon, for å rendre din egen label. Funksjonen mottar props
du kan spre på labelen.`}</p>
    <DSExample name="form_Checkbox_renderProps" mdxType="DSExample" />
    <p>{`Komponenten videresender alle udokumenterte props til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<input />`}</code>{`-elementet.`}</p>
    <h2>{`PhoneNumber`}</h2>
    <p>{`Telefonnummer med landskode:`}</p>
    <DSExample name="form_PhoneNumber" mdxType="DSExample" />
    <p>{`Ved feil eller manglende landkode:`}</p>
    <DSExample name="form_PhoneNumber_countryCode_invalid" mdxType="DSExample" />
    <p>{`Ved feil eller manglende telefonnummer:`}</p>
    <DSExample name="form_PhoneNumber_PhoneNumber_invalid" mdxType="DSExample" />
    <p>{`Feilmelding i begge feltene:`}</p>
    <DSExample name="form_PhoneNumber_PhoneNumber_countryCode_invalid" mdxType="DSExample" />
    <h2>{`RadioButton`}</h2>
    <p>{`Brukes når kunden kan velge ett av få valg. Hvis valgene er korte og
konsise, kan man ha dem ved siden av hverandre:`}</p>
    <DSExample name="form_RadioButton_inline" mdxType="DSExample" />
    <UUTestReport reportID="Form_RadioButton" mdxType="UUTestReport" />
    <p>{`Har valgene litt mer tekst, så bør valgene komme under hverandre:`}</p>
    <DSExample name="form_RadioButton" mdxType="DSExample" />
    <p>{`Noen valg kan være mer kompliserte, og ikke like selvforklarende. Da kan man
legge til en tipstekst:`}</p>
    <DSExample name="form_RadioButton_tooltip" mdxType="DSExample" />
    <h2>{`RadioButtonInputGroup`}</h2>
    <p>{`Radioknapper skal grupperes med en felles overskrift som gir brukeren
konteksten de trenger for å gjøre valget under. Denne overskriften kan ofte
være et spørsmål:`}</p>
    <DSExample name="form_RadioButtonInputGroup" mdxType="DSExample" />
    <UUTestReport reportID="Form_RadioButtonInputGroup" mdxType="UUTestReport" />
    <p>{`Default oppførsel er at det holdes av plass under inputfeltene for å vise en feilmelding uten at innholdet lengre ned endres. Dersom man ikke ønsker dette kan det skrus
av med å sette `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`extraMargin`}</code>{` prop til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`false`}</code>{`.`}</p>
    <DSExample name="form_RadioButtonInputGroup_extraMarginFalse" mdxType="DSExample" />
    <h2>{`RadioBlock`}</h2>
    <p>{`Radioblokker brukes når vi ber brukeren om å ta valg som krever en del.`}</p>
    <DSExample name="form_RadioBlock" mdxType="DSExample" />
    <h2>{`RadioSwitch`}</h2>
    <p>{`Brukes når kunden skal ta stilling til enkle valg - typisk i formen "ja/nei", eller "av/på" og hvor du i tillegg har behov for en lagreknapp. Har du behov for mer enn 3 radio-switcher i en liste, skal du bruke radioknapper.`}</p>
    <p>{`Radio-switcher uten defaultvalg.`}</p>
    <DSExample name="form_RadioSwitch" mdxType="DSExample" />
    <p>{`Radio-switcher med defaultvalg.`}</p>
    <DSExample name="form_RadioSwitch_selectedValue" mdxType="DSExample" />
    <p>{`Radio-switcher med feilmelding på brukerens valg.`}</p>
    <DSExample name="form_RadioSwitch_fieldMessage" mdxType="DSExample" />
    <p>{`Radio-switcher med feilmelding der brukeren ikke har gjort et valg.`}</p>
    <DSExample name="form_RadioSwitch_fieldMessageUnselected" mdxType="DSExample" />
    <h2>{`TextArea`}</h2>
    <DSExample name="form_TextArea" mdxType="DSExample" />
    <p>{`Styr fokus med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ref`}</code>{`:
Du kan få en referanse til textarea-feltet ved å sende inn en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ref`}</code>{`-prop. Brukes typisk til å fokusere et felt programmatisk.`}</p>
    <DSExample name="form_TextArea_ref" mdxType="DSExample" />
    <h2>{`ToggleSwitch`}</h2>
    <p>{`ToggleSwitch passer for valg som kan skrus av eller på, for eksempel innstillinger. Respons på valg bør skje umiddelbart, uten behov for å trykke på en lagreknapp i tillegg. Ønsker du å lage et skjema med tilsvarende interaksjon og lagreknapp i tillegg, vurder å bruke `}<a parentName="p" {...{
        "href": "#radioswitch"
      }}>{`RadioSwitch`}</a>{` i stedet.`}</p>
    <p>{`Komponenten består av teksten som sendes inn, hjelpetekst for "Av" og "På", og selve switchen. Den kan tilpasses med en rekke properties.`}</p>
    <DSExample name="form_ToggleSwitch" mdxType="DSExample" />
    <h3>{`Valgfrie props`}</h3>
    <p>{`Hjelpetekst for "Av" og "På" kan tilpasses med henholdsvis `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onText`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`offText`}</code>{`.`}</p>
    <DSExample name="form_ToggleSwitch_customOnOff" mdxType="DSExample" />
    <p>{`Hjelpeteksten kan også skjules helt, ved hjelp av `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hideOnOff`}</code>{`.`}</p>
    <DSExample name="form_ToggleSwitch_hideOnOff" mdxType="DSExample" />
    <p>{`En ekstra linje med tekst i label kan legges til med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`description`}</code>{`.`}</p>
    <DSExample name="form_ToggleSwitch_description" mdxType="DSExample" />
    <p>{`Default språk for "Av" og "På" kan endres med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`locale`}</code>{`. Gyldige verdier er `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nb`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nn`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`en`}</code>{`.`}</p>
    <DSExample name="form_ToggleSwitch_locale" mdxType="DSExample" />
    <h2>{`Tooltip`}</h2>
    <p>{`Om du ikke bruker InputGroup kan du fremdeles bruke deler av den, som f.eks. Tooltip.`}</p>
    <DSExample name="form_Tooltip" mdxType="DSExample" />
    <UUTestReport reportID="Form_Tooltip" mdxType="UUTestReport" />
    <p>{`Dersom du bygger din egen hjelpefunksjon og kun trenger knappen så er det ikke nødvendig
å sende med innholdet, du kan bruke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onClick`}</code>{` prop til å reagere på knappetrykk men da må du
også sende med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-controls`}</code>{` som skal peke på iden til elementet som vises/skjules med knappen din.`}</p>
    <DSExample name="form_Tooltip_external" mdxType="DSExample" />
    <h2>{`Datepicker`}</h2>
    <DSExample name="datepicker_Datepicker" mdxType="DSExample" />
    <p>{`Har en egen styling dersom feltet merkes med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-invalid="true"`}</code>{`:`}</p>
    <DSExample name="datepicker_Datepicker_invalid" mdxType="DSExample" />
    <p>{`Kan rendres med kalenderen over inputfeltet:`}</p>
    <DSExample name="datepicker_Datepicker_calendarAbove" mdxType="DSExample" />
    <p>{`Kan rendres i full bredde:`}</p>
    <DSExample name="datepicker_Datepicker_fullWidth" mdxType="DSExample" />
    <p>{`Kan rendres på engelsk og nynorsk, i tillegg til bokmål:`}</p>
    <DSExample name="datepicker_Datepicker_nn" mdxType="DSExample" />
    <DSExample name="datepicker_Datepicker_en" mdxType="DSExample" />
    <h2>{`Calendar`}</h2>
    <p>{`Kalender-delen av Datepicker kan brukes for seg om man trenger det.`}</p>
    <DSExample name="datepicker_Calendar" mdxType="DSExample" />
    <p>{`Denne kan også settes til å bruke engelsk og nynorsk som språk:`}</p>
    <DSExample name="datepicker_Calendar_nn" mdxType="DSExample" />
    <DSExample name="datepicker_Calendar_en" mdxType="DSExample" />
    <h2>{`FileUpload`}</h2>
    <p>{`En komponent for å laste opp filer, for eksempel Excel-ark eller annen dokumentasjon.`}</p>
    <DSExample name="file_upload_FileUpload" mdxType="DSExample" />
    <p>{`Opplastingsprosessen er som følger:`}</p>
    <ol>
      <li parentName="ol">{`Brukeren velger filer i native filopplastingsview eller via 'drag and drop'`}</li>
      <li parentName="ol">{`Browseren mottar event-callback, med info om filene, om at brukeren har valg filer for opplasting, men disse er enda
ikke lastet opp`}</li>
      <li parentName="ol">{`Konsumenter av `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`FileUpload`}</code>{` må selv starte nedlasting av valgte filer fra klientens filsystem (ved å bruke
`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`file-content.js`}</code>{`)`}</li>
      <li parentName="ol">{`Konsumenter oppretter et objekt med info om filene og sender disse inn til `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`FileUpload`}</code>{` som `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`files`}</code>{`.`}</li>
      <li parentName="ol">{`Det er opp til konsumenten selv å avvise filer med feil størrelse eller type (se eksempel over).`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`files`}</code>{` objektet er indeksert på navn med selve `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`name`}</code>{` påkrevet, mens `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`error`}</code>{` og `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`document`}</code>{` er optional.`}</li>
      <li parentName="ol">{`Et eksempel på `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`files`}</code>{` som inkluderer de 3 mulighetene:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` files `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`fileBeingUploaded`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fileBeingUploaded'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`fileWithError`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fileWithError'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`error`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Feil filtype'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`fileUploaded`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fileUploaded'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`document`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'(data)'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2>{`Universell utforming`}</h2>
    <p>{`Husk å markere skjemafelt som obligatoriske! Dette kan gjøres på to måter.
Man kan enten starte skjemainstruksjon med "påkrevde felter er markert med (`}<em parentName="p">{`) og inkludere (`}</em>{`) i hvert obligatoriske skjemafelt.
Alternativt kan man skrive (påkrevd) i hver enkelt obligatoriske skjemafelt-label.`}</p>
    <ul>
      <li parentName="ul">{`Beskriv feilen: Bruk aria-describedby for å knytte feilmeldinger til inputfeltene.`}</li>
      <li parentName="ul">{`Fokusrekkefølge: Sørg for at brukere kan navigere gjennom skjemaet med tastaturet i en logisk rekkefølge.`}</li>
      <li parentName="ul">{`Fokusstil: Bruk CSS for å tydelig markere elementer som er i fokus`}</li>
      <li parentName="ul">{`Ekstra informasjon: Bruk placeholder tekst for å gi ekstra informasjon, men ikke som en erstatning for input label.`}</li>
      <li parentName="ul">{`Meningsfull knappetekst: Knapper bør ha klar og beskrivende tekst for funksjonen til knappen.`}</li>
      <li parentName="ul">{`Instruksjoner: Gi tydelige instruksjoner for hva som forventes i hvert felt.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      